import Mock from "../mock";

const firstName = "Tomer";
const lastName = "Ovadia";
const email = "o.tomerr@gmail.com";

const database = {
  information: {
    name: `${firstName} ${lastName}`,
    aboutContent:
      "With 10 years of expereience in full-stack web development and systems architecture, I am passionate about building state-of-the-art, easy to use, user-friendly web applications.",
    // age: 24,
    phone: "",
    nationality: "Israeli",
    language: "Hebrew, English",
    email: email,
    title: "Senior Software Engineer",
    address: "Tel Aviv, israel",
    // freelanceStatus: "Available",
    socialLinks: {
      facebook: "",
      twitter: "",
      pinterest: "",
      behance: "",
      email: `mailto:${email}`,
      linkedin: "https://www.linkedin.com/in/tomerovadia",
      dribbble: "",
      github: "https://github.com/otomer",
      medium: "https://medium.com/@tomero",
    },
    brief: "Determined, Driven, Dedicated </>",
    // brandImage: "/images/brand-image.jpg",
    // aboutImage: "/images/about-image.jpg",
    // aboutImageLg: "/images/about-image-lg.jpg",
    cvfile: "/files/empty.pdf",
    aboutContactCta: "Contact Me for CV",
  },
  services: [
    {
      title: "User Experience",
      icon: "color-pallet",
      details:
        "Creating the right user experience has to be intuitive, valuable, and enjoyable to drive engagement, conversions, and repeat visitors.",
    },
    {
      title: "System Architecture",
      icon: "vector",
      details:
        "Establishing the right architecture must find the balance of a robust, feasible, durable, verifiable, flexible, traceable and elegant system.",
    },
    {
      title: "Web Development",
      icon: "code",
      details:
        "As technology advances, the industry will only grow. Stay on track and master the required skills to understand the future direction of the WWW.",
    },
  ],
  reviews: [
    {
      id: 1,
      content:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.",
      author: {
        name: "Burdette Turner",
        designation: "Web Developer, Abc Company",
      },
    },
    {
      id: 2,
      content:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.",
      author: {
        name: "Susan Yost",
        designation: "Client",
      },
    },
    {
      id: 3,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
      author: {
        name: "Irving Feeney",
        designation: "Fiverr Client",
      },
    },
  ],
  skills: [
    {
      title: "Javascript",
      value: 95,
    },
    {
      title: "Java",
      value: 90,
    },
    {
      title: "React",
      value: 90,
    },
    {
      title: "TypeScript",
      value: 85,
    },
    {
      title: "SQL",
      value: 90,
    },
    {
      title: "HTML5",
      value: 95,
    },
    {
      title: "CSS3",
      value: 90,
    },
    {
      title: "NodeJS",
      value: 85,
    },
  ],
  portfolios: [
    {
      id: 1,
      title: "BrowserBuddy Chrome Extension",
      subtitle: "View all the data available about you when browse a page.",
      imageUrl: "/images/browserbuddy-440x280.png",
      largeImageUrl: ["/images/browserbuddy-920x680.png"],
      url: "https://browserbuddy.me/",
    },
    {
      id: 2,
      title: "Coronavirus Pandemic Live Statistics",
      subtitle:
        "Coronavirus (COVID-19) pandemic live statistics and news tracking historical data, daily charts, graphs, a per country view and more!",
      imageUrl: "/images/portfolio-image-4.jpg",
      largeImageUrl: [
        "/images/portfolio-image-4-lg.jpg",
        "/images/portfolio-image-4-lg2.jpg",
      ],
      url: "https://coronavirus-epidemic.com",
    },
  ],
  experience: {
    educationExperience: [
      {
        details: "Taking part in miLAB (Media Innovation Lab) Program",
        graduation: "Bachelor, Computer Science",
        id: 1,
        university: "IDC Herzliya, Efi Arazi School",
        year: "2011 - 2014",
      },
      {
        details: "448 hours course",
        graduation: "Microsoft .NET Developer",
        id: 2,
        university: "John Bryce",
        year: "2009 - 2010",
      },
      {
        details: "",
        graduation: "High School Full Matriculation",
        id: 3,
        university: "Tel Aviv, Irony Yud Dalet High School",
        year: "2002 - 2005",
      },
    ],
    workingExperience: [
      {
        company: "Booking.com",
        details: "",
        id: 1,
        position: "Senior Software Engineer",
        year: "01/2020 - Now",
      },
      {
        company: "WeWork",
        details: "",
        id: 2,
        position: "Software Engineer",
        year: "12/2018 - 01/2020",
      },
      {
        company: "LivePerson",
        details: "",
        id: 3,
        position: "Senior Software Engineer",
        year: "09/2014 - 10/2018",
      },
      {
        company: "Elipaz Software",
        details: "",
        id: 4,
        position: "Web Developer",
        year: "05/2013 - 08/2014",
      },
      {
        company: "miLAB Media Innovation Lab, IDC",
        details: "",
        id: 5,
        position: "Mobile Web Developer",
        year: "10/2013 - 07/2014",
      },
      {
        company: "Walla! and Yad 2",
        details: "",
        id: 6,
        position: "Web Developer",
        year: "5/2010 - 9/2012",
      },
    ],
  },
  blogs: [
    {
      id: 1,
      title: "Markdown & Html supported blog.",
      featuredImage: "/images/blog-image-1.jpg",
      filesource: "../../blog/markdown-html-supported-blog.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 2,
      title: "Installing NodeJS on your device.",
      featuredImage: "/images/blog-image-2.jpg",
      filesource: "../../blog/installing-nodejs-on-your-device.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 3,
      title: "UI/UX design starter with Adobe XD.",
      featuredImage: "/images/blog-image-3.jpg",
      filesource: "../../blog/uiux-design-starter-with-adobe-xd.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 4,
      title: "Boost your post for increasing sales.",
      featuredImage: "/images/blog-image-4.jpg",
      filesource: "../../blog/boost-your-post-for-increasing-sales.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 5,
      title: "Difference between GatsbyJS & NextJS.",
      featuredImage: "/images/blog-image-5.jpg",
      filesource: "../../blog/difference-between-gatsbyjs-and-nextjs.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 6,
      title: "How to choose javascript framework for project.",
      featuredImage: "/images/blog-image-6.jpg",
      filesource:
        "../../blog/how-to-choose-javascript-framework-for-project.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 7,
      title: "Web automation with python language.",
      featuredImage: "/images/blog-image-7.jpg",
      filesource: "../../blog/web-automation-with-python-language.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 8,
      title: "Time to use latest technology for creating a website.",
      featuredImage: "/images/blog-image-8.jpg",
      filesource:
        "../../blog/time-to-use-latest-technology-for-creating-a-website.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 9,
      title: "Think out of the box.",
      featuredImage: "/images/blog-image-9.jpg",
      filesource: "../../blog/think-out-of-the-box.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 10,
      title: "Trending designs in 2020.",
      featuredImage: "/images/blog-image-1.jpg",
      filesource: "../../blog/trending-designs-in-2020.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 11,
      title: "How to get 10k instagram followers?",
      featuredImage: "/images/blog-image-2.jpg",
      filesource: "../../blog/how-to-get-10k-instagram-followers.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 12,
      title: "What NodeJS can do?",
      featuredImage: "/images/blog-image-3.jpg",
      filesource: "../../blog/what-nodejs-can-do.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 13,
      title: "Futures of javascript.",
      featuredImage: "/images/blog-image-4.jpg",
      filesource: "../../blog/future-of-javascript.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 14,
      title: "Popular javascript library in 2020.",
      featuredImage: "/images/blog-image-5.jpg",
      filesource: "../../blog/popular-javascript-library-in-2020.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 15,
      title: "Promrammers must read books.",
      featuredImage: "/images/blog-image-6.jpg",
      filesource: "../../blog/programmers-must-read-books.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
  ],
  contactInfo: {
    phoneNumbers: ["", ""],
    emailAddress: ["", ""],
    address: "",
  },
};

Mock.onGet("/api/information").reply((config) => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply((config) => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply((config) => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply((config) => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply((config) => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply((config) => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply((config) => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply((config) => {
  const response = database.contactInfo;
  return [200, response];
});
