import React, { useEffect, useState } from "react";

import Layout from "../components/Layout";
import Progress from "../components/Progress";
// import Resume from "../components/Resume";
import Sectiontitle from "../components/Sectiontitle";
// import Smalltitle from "../components/Smalltitle";
import TrackVisibility from "react-on-screen";
import axios from "axios";

function Skills() {
  const [skills, setSkills] = useState([]);
  // const [workingExperience, setWorkingExperience] = useState([]);
  // const [educationExperience, setEducationExperience] = useState([]);

  useEffect(() => {
    axios.get("/api/skills").then((response) => {
      setSkills(response.data);
    });
  }, []);

  return (
    <Layout>
      <div className="mi-skills-area mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title="My Skills" />
          <div className="mi-skills">
            <div className="row mt-30-reverse">
              {skills.map((skill) => (
                <TrackVisibility
                  once
                  className="col-lg-6 mt-30"
                  key={skill.title}
                >
                  <Progress title={skill.title} percentage={skill.value} />
                </TrackVisibility>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Skills;
